// library imports
import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider, message, notification } from "antd";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// style imports
import "./styles/index.css";
// config imports
import { route_basename } from "./configs/configs";
// context imports
import { IndexContext } from "./context/IndexContext";
// hook imports
import useThemeSwitcher from "./hooks/useThemeSwitcher";
// component/page imports
import MainLayout from "./layout/MainLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import LoginRecordPage from "./pages/login/LoginRecordPage";
import BundleListPage from "./pages/bundle_list/BundleListPage";
import RebarVerificationPage from "./pages/rebar_verification/RebarVerificationPage";
import CameraTest from "./pages/test/CameraTest";
import QrScannerTest from "./pages/test/QrScannerTest";
import TableDataTest from "./pages/test/TableDataTest";
import ProtectedRoute from "./routes/ProtectedRoutes";
import AIRebarCountPage from "./pages/ai_rebar_count/AIRebarCountPage";
import TraceabilityPage from "./pages/traceability/TraceabilityPage";
import MillCertificationPage from "./pages/mill_certification/MillCertificationPage";
import StatisticPage from "./pages/statistic/StatisticPage";
import DownloadTest from "./pages/test/DownloadTest";
import ChangePassword from "./pages/account/ChangePassword";
import ManagementPage from "./pages/management/ManagementPage";
import MaterialPropertiesPage from "./pages/material_properties/MaterialPropertiesPage";

const App = () => {
  // load theme switcher
  const [appTheme, updateTheme, isLightTheme] = useThemeSwitcher();

  // for triggering message
  const [messageApi, messageApiContext] = message.useMessage();
  // for triggering notification
  const [notificationApi, notificationApiContext] =
    notification.useNotification();

  return (
    <ConfigProvider theme={{ ...appTheme }}>
      {messageApiContext}
      {notificationApiContext}
      <IndexContext.Provider
        value={{
          messageApi: messageApi,
          notificationApi: notificationApi,
          isLightTheme: isLightTheme,
          updateTheme: updateTheme,
        }}
      >
        <BrowserRouter>
          <Routes>
            {/* Login Route */}
            <Route path="Login" element={<LoginPage />}></Route>
            <Route path="LoginRecord" element={<LoginRecordPage />}></Route>
            {/* All content pages */}
            <Route element={<MainLayout updateTheme={updateTheme} />}>
              {/* Sub route for Dashboard */}
              <Route path="Dashboard" element={<DashboardPage />}></Route>
              {/* Sub route for Operator */}
              <Route path="AIRebarCount" element={<AIRebarCountPage />}></Route>
              {/* Sub route for Bundle List*/}
              <Route
                path="BundleList"
                element={
                  <ProtectedRoute disabledUserTypes={[4]}>
                    <BundleListPage />
                  </ProtectedRoute>
                }
              ></Route>
              {/* Sub route for Rebar Verification*/}
              <Route
                path="RebarVerification"
                element={
                  <ProtectedRoute disabledUserTypes={[4]}>
                    <RebarVerificationPage />
                  </ProtectedRoute>
                }
              ></Route>
              {/* Sub route for Statistic*/}
              <Route
                path="Statistic"
                element={
                  <ProtectedRoute allowedUserTypes={[0]}>
                    <StatisticPage />
                  </ProtectedRoute>
                }
              ></Route>
              {/* Sub route for Traceability*/}
              <Route
                path="Traceability"
                element={
                  <ProtectedRoute allowedUserTypes={[0]}>
                    <TraceabilityPage />
                  </ProtectedRoute>
                }
              ></Route>
              {/* Sub route for Management */}
              <Route
                path="Management"
                element={
                  <ProtectedRoute allowedUserTypes={[0]}>
                    <ManagementPage />
                  </ProtectedRoute>
                }
              ></Route>
              {/* Sub route for Mill Certification*/}
              <Route
                path="MillCertification"
                element={
                  <ProtectedRoute allowedUserTypes={[0]}>
                    <MillCertificationPage />
                  </ProtectedRoute>
                }
              ></Route>
              {/* Sub route for Material Properties*/}
              <Route
                path="MaterialProperties"
                element={
                  <ProtectedRoute allowedUserTypes={[0]}>
                    <MaterialPropertiesPage />
                  </ProtectedRoute>
                }
              ></Route>

              {/* Sub route for Account*/}
              <Route path="Account">
                <Route
                  path="ChangePassword"
                  element={<ChangePassword />}
                ></Route>
              </Route>

              {/* Sub route for Test*/}
              <Route path="Test">
                <Route
                  path="CameraTest"
                  element={
                    <ProtectedRoute allowedUserTypes={[0, 1]}>
                      <CameraTest />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="QrScannerTest"
                  element={
                    <ProtectedRoute allowedUserTypes={[0, 1]}>
                      <QrScannerTest />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="DownloadTest"
                  element={
                    <ProtectedRoute allowedUserTypes={[0, 1]}>
                      <DownloadTest />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="TableDataTest"
                  element={
                    <ProtectedRoute allowedUserTypes={[0, 1]}>
                      <TableDataTest />
                    </ProtectedRoute>
                  }
                ></Route>
              </Route>
            </Route>
            {/* Default all unknown route to login page */}
            <Route path="*" element={<Navigate to="/Login" />} />
          </Routes>
        </BrowserRouter>
      </IndexContext.Provider>
    </ConfigProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
