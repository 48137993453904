import { useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import useDownloadFile from "../../DownloadFile/downloadFile";

function useDownloadInventory() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const { downloadFile } = useDownloadFile();

  const downloadInventory = useCallback(
    (download_data, setDownloadLoading) => {
      setDownloadLoading(true);
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "statistic/downloadInventory",
          params: {
            download: download_data,
          },
          setLoading: setDownloadLoading,
          onSuccess: (fetch_ouput) => {
            if (fetch_ouput.status) {
              downloadFile(fetch_ouput.folder_path, fetch_ouput.file_name);
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_ouput.error,
              });
            }
            resolve(fetch_ouput);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to download inventory excel",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Download Invetory Excel), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { downloadInventory };
}

export default useDownloadInventory;
