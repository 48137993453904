import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import DataTable from "../../common/DataTable";
import { useState } from "react";
import FunctionalButton from "../../common/FunctionalButton";

export default function UserManagementTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const USER_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
    },
    {
      title: "Factory",
      dataIndex: "factory",
      key: "factory",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Create Date Time",
      dataIndex: "create_date_time",
      key: "create_date_time",
    },
    {
      title: "Last Update By",
      dataIndex: "last_update_by",
      key: "last_update_by",
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(record.action)}
              type=""
              width="40px"
              icon={<EditOutlined />}
              disabled={loadRowKey == record.key && deleteLoading}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleDelete(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to delete this material properties?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
              trigger_loading={loadRowKey == record.key && deleteLoading}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={USER_TABLE_COLUMN}
      table_data={tableData}
      pageSize={10}
    />
  );
}
