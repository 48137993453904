import { Col, Flex, Row } from "antd";
import DailyTrendCard from "../../components/dashboard/DailyTrendCard";
import IndicatorCard from "../../components/dashboard/IndicatorCard";
import InventoryCard from "../../components/dashboard/InventoryCard";
import "../../styles/dashboard/Dashboard.css";
import { useContext, useEffect, useState } from "react";
import { IndexContext } from "../../context/IndexContext";
import useGetBundleNonAdjustedRate from "../../functions/Dashboard/Indicator/getBundleNonAdjustedRate";
import useGetBundleCurrentOutput from "../../functions/Dashboard/Indicator/getBundleCurrentOutput";
import useGetFactoryBundles from "../../functions/Dashboard/Inventory/getFactoryBundles";
import useGetOneMonthStuckBundles from "../../functions/Dashboard/Inventory/getOneMonthStuckBundles";
import useGetStuckBundles from "../../functions/Dashboard/Inventory/getStuckBundles";
import useGetShiftBundles from "../../functions/Dashboard/DailyTrendChart/getShiftBundles";
import useGetOperatorBundles from "../../functions/Dashboard/DailyTrendChart/getOperatorBundles";
import useGetProductionBundles from "../../functions/Dashboard/DailyTrendChart/getProductionBundles";
import useGetDeliveryBundles from "../../functions/Dashboard/DailyTrendChart/getDeliveryBundles";

function DashboardPage() {
  const { isLightTheme } = useContext(IndexContext);
  const [chartTextColor, setChartTextColor] = useState(
    isLightTheme ? "#000000" : "#ffffff"
  );
  const [indicatorLoading, setIndicatorLoading] = useState(false);
  const [inventoryLoading, setInventoryLoading] = useState(false);
  const [dailyTrendLoading, setDailyTrendLoading] = useState(false);

  const { getBundleNonAdjustedRate, nonAdjustedRateChart } =
    useGetBundleNonAdjustedRate();
  const { getBundleCurrentOutput, currentOutputChart } =
    useGetBundleCurrentOutput();

  const { getFactoryBundles, factoryChart } = useGetFactoryBundles();
  const { getOneMonthStuckBundles, oneMonthStuckChart } =
    useGetOneMonthStuckBundles();
  const { getStuckBundles, stuckBundlesChart } = useGetStuckBundles();

  const { getShiftBundles, shiftChart, shiftSummary } = useGetShiftBundles();
  const { getOperatorBundles, operatorChart, operatorSummary } =
    useGetOperatorBundles();
  const { getProductionBundles, productionChart, productionSummary } =
    useGetProductionBundles();
  const { getDeliveryBundles, deliveryChart, deliverySummary } =
    useGetDeliveryBundles();

  useEffect(() => {
    setChartTextColor(isLightTheme ? "#000000" : "#ffffff");
  }, [isLightTheme]);

  useEffect(() => {
    getBundleNonAdjustedRate(setIndicatorLoading);
    getBundleCurrentOutput(setIndicatorLoading);
    getFactoryBundles(setInventoryLoading);
    getOneMonthStuckBundles(setInventoryLoading);
    getStuckBundles(setInventoryLoading);
    getShiftBundles(setDailyTrendLoading);
    getOperatorBundles(setDailyTrendLoading);
    getProductionBundles(setDailyTrendLoading);
    getDeliveryBundles(setDailyTrendLoading);
  }, []);

  return (
    <>
      <Flex vertical="vertical" gap="small" style={{ height: "100%" }}>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={24} lg={10}>
            <IndicatorCard
              chartTextColor={chartTextColor}
              donutChartData={nonAdjustedRateChart}
              lineChartData={currentOutputChart}
              loading={indicatorLoading}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            <InventoryCard
              chartTextColor={chartTextColor}
              pieChartData={factoryChart}
              donutChartData={oneMonthStuckChart}
              barChartData={stuckBundlesChart}
              loading={inventoryLoading}
            />
          </Col>
        </Row>
        <Row style={{ height: "100%" }}>
          <Col span={24}>
            <DailyTrendCard
              chartTextColor={chartTextColor}
              shiftSummary={shiftSummary}
              shiftChartData={shiftChart}
              operatorSummary={operatorSummary}
              operatorChartData={operatorChart}
              productionSummary={productionSummary}
              productionChartData={productionChart}
              deliverySummary={deliverySummary}
              deliveryChartData={deliveryChart}
              loading={dailyTrendLoading}
            />
          </Col>
        </Row>
      </Flex>
    </>
  );
}

export default DashboardPage;
