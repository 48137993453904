import { Button, Card, Flex, Typography } from "antd";
import TraceabilityTable from "../../components/traceability/TraceabilityTable";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import useGetTagIDTraceability from "../../functions/Traceability/getTagIDTraceability";
import useDownloadTraceability from "../../functions/Traceability/downloadTraceability";
import useGetTraceabilityImage from "../../functions/Traceability/getTraceabilityImage";
import TraceabilitySearchForm from "../../components/traceability/TraceabilitySearchForm";

const { Text } = Typography;

export default function TraceabilityPage() {
  const [searchModal, setSearchModal] = useState(false);
  // For handle datepicker startdate
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  // For handle datepicker enddate
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  const {
    getTagIDTraceability,
    traceabilityTable,
    traceabilityLoading,
    traceabilityDownload,
  } = useGetTagIDTraceability();

  const { getTraceabilityImage, imageLoading } = useGetTraceabilityImage();

  const { downloadTraceability, downloadLoading } = useDownloadTraceability();

  const [filterTraceabilityTable, setFilterTraceabilityTable] =
    useState(traceabilityTable);

  const resetFilter = () => {
    setFilterTraceabilityTable(traceabilityTable);
  };

  const handleDateFilter = () => {
    getTagIDTraceability(startDateSelection, endDateSelection);
  };

  const handleDownload = () => {
    downloadTraceability(traceabilityDownload);
  };

  useEffect(() => {
    setFilterTraceabilityTable(traceabilityTable);
  }, [traceabilityTable]);

  useEffect(() => {
    getTagIDTraceability(startDateSelection, endDateSelection);
  }, []);

  return (
    <>
      <Card
        className="context-card"
        title={
          <Flex justify="space-between" align="center" wrap="wrap" gap="small">
            <Text>Traceability Table</Text>
            <Flex gap="small">
              <CustomRangePicker
                startDateSelection={startDateSelection}
                setStartDateSelection={setStartDateSelection}
                endDateSelection={endDateSelection}
                setEndDateSelection={setEndDateSelection}
                disabled={traceabilityLoading}
                search={true}
                onFilterClick={handleDateFilter}
              />
              <Button
                icon={
                  downloadLoading ? <LoadingOutlined /> : <DownloadOutlined />
                }
                disabled={traceabilityLoading || downloadLoading}
                onClick={handleDownload}
              />
              <Button
                children="Reset"
                disabled={traceabilityLoading}
                onClick={resetFilter}
              />
              <Button
                children="Advanced Search"
                type="primary"
                onClick={() => setSearchModal(true)}
                disabled={traceabilityLoading}
              />
            </Flex>
          </Flex>
        }
        loading={traceabilityLoading}
      >
        <TraceabilityTable
          tableData={filterTraceabilityTable}
          getTraceabilityImage={getTraceabilityImage}
          imageLoading={imageLoading}
        />
      </Card>
      <TraceabilitySearchForm
        modalOpen={searchModal}
        setModalOpen={setSearchModal}
        tableData={traceabilityTable}
        setFilterTable={setFilterTraceabilityTable}
      />
    </>
  );
}
