import { Button, Card, Col, Flex, Row, Tooltip } from "antd";
import { ExclamationCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/rebar_verification/RebarVerificationPage.css";
import RebarVerificationImage from "../../components/rebar_verification/RebarVerificationImage";
import useGetRebarInfo from "../../functions/RebarVerification/getRebarInfo";
import useUpdateRebarInfo from "../../functions/RebarVerification/updateRebarInfo";
import RebarVerificationForm from "../../components/rebar_verification/RebarVerificationForm";
import VerificationTimeOutModal from "../../components/rebar_verification/VerificationTimeOutModal";
import useUpdateRebarVerifyStatus from "../../functions/RebarVerification/updateRebarVerifyStatus";
import useContinueVerifyRebar from "../../functions/RebarVerification/continueVerifyRebar";
import useGetRebarOverallAccuracy from "../../functions/RebarVerification/getRebarOverallAccuracy";
import RebarVerificationEditImage from "../../components/rebar_verification/RebarVerificationEditImage";
import RebarVerificationChooseImage from "../../components/rebar_verification/RebarVerificationChooseImage";
import { IndexContext } from "../../context/IndexContext";
import useGetRebarImageCoordinate from "../../functions/RebarVerification/getRebarImageCoordinate";

export default function RebarVerificationPage() {
  // To navigate
  const navigate = useNavigate();

  const { authenticationState } = useContext(IndexContext);

  // To get the Tag ID after user navigate
  const location = useLocation();
  const { value: rebar_key } = location.state || {};

  // Handle Timeout Modal
  const [timeOut, setTimeOut] = useState(false);

  const [coordinate, setCoordinate] = useState({
    circle: [],
    cross: [],
  });
  const [selectImage, setSelectImage] = useState(null);

  const [phase2, setPhase2] = useState(false);

  // Api Function for Get Rebar Info Api
  const {
    getRebarInfo,
    imageSrc,
    rebarInfo,
    userCount,
    isLoading,
    setUserCount,
  } = useGetRebarInfo();
  // Api Function for Get Rebar Image Coordinate
  const {
    getRebarImageCoordinate,
    circles,
    crosses,
    shapeSize,
    setCircles,
    setCrosses,
    coordinateLoading,
  } = useGetRebarImageCoordinate();
  // Api Function to Update Rebar Info
  const { updateRebarInfo, updateLoading } = useUpdateRebarInfo();
  // Api Function to Update Rebar Verify Status
  const { updateRebarVerifyStatus } = useUpdateRebarVerifyStatus();
  // Api Funciotn for Continue Verify Rebar
  const { continueVerifyRebar } = useContinueVerifyRebar();
  // Api Function for Get Rebar Overall Accuracy
  const { getRebarOverallAccuracy, overallAccuracy, accuracyLoading } =
    useGetRebarOverallAccuracy();

  // Function to cancel the Verification and navigate back to Bundle List Page
  const navigateBack = () => navigate(-1, { replace: true });

  // Function to add Rebar Count
  const addCount = () => setUserCount(userCount + 1);
  // Function to minus Rebar Count
  const minusCount = () => setUserCount(userCount - 1);

  // Function to handle submit button
  const handleSubmit = () => {
    updateRebarInfo(rebar_key, userCount, navigateBack, coordinate);
  };
  // Function for cancel update the rebar
  const handleCancel = () => updateRebarVerifyStatus(rebar_key, navigateBack);
  // Function to continue verify rebar
  const handleContinue = () =>
    continueVerifyRebar(rebar_key, setTimeOut, navigateBack);

  // To check is there have the Rebar ID or not
  useEffect(() => {
    if (rebar_key === undefined) {
      navigate("/BundleList");
    } else {
      getRebarInfo(rebar_key, navigateBack, setSelectImage);
      getRebarImageCoordinate(rebar_key);
      getRebarOverallAccuracy();
    }

    const time_interval = setInterval(() => {
      continueVerifyRebar(rebar_key, setTimeOut, navigateBack);
    }, 180000);

    const interval = setInterval(() => {
      setTimeOut(true);
    }, 14400000);

    return () => {
      clearInterval(interval);
      clearInterval(time_interval);
    };
  }, []);

  // If the modal open more than one minute auto update the verify status and navigate back
  useEffect(() => {
    if (timeOut) {
      const interval = setInterval(() => {
        updateRebarVerifyStatus(rebar_key, navigateBack);
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [timeOut]);

  return (
    <>
      <Card
        className="context-card"
        title={
          <Flex
            gap="middle"
            align="center"
            justify="space-between"
            wrap
            style={{ padding: "5px" }}
          >
            <Flex gap="middle">
              <span>Rebar Verification</span>
              <Tooltip
                title="Click the image for preview"
                children={<ExclamationCircleOutlined />}
              />
            </Flex>
            {authenticationState.user_type == 0 && (
              <Button
                icon={<ReloadOutlined />}
                type="ghost"
                onClick={() => setPhase2(!phase2)}
              />
            )}
          </Flex>
        }
        loading={isLoading || accuracyLoading || coordinateLoading}
      >
        <Row
          gutter={[24, 24]}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            lg={24}
            xl={14}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {phase2 ? (
              <RebarVerificationEditImage
                imageSrc={selectImage}
                addCount={addCount}
                minusCount={minusCount}
                shapeSize={shapeSize}
                setCoordinate={setCoordinate}
                circles={circles}
                crosses={crosses}
                setCircles={setCircles}
                setCrosses={setCrosses}
              />
            ) : (
              <RebarVerificationImage imageSrc={imageSrc} />
            )}
          </Col>
          <Col
            lg={24}
            xl={10}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Row gutter={[16, 16]}>
              {phase2 && (
                <RebarVerificationChooseImage
                  imageSrc={imageSrc}
                  setSelectImage={setSelectImage}
                />
              )}
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <RebarVerificationForm
                  rebarInfo={rebarInfo}
                  rebarAccuracy={overallAccuracy}
                  userCount={userCount}
                  setUserCount={setUserCount}
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  updateLoading={updateLoading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <VerificationTimeOutModal
        timeOut={timeOut}
        handleContinue={handleContinue}
        handleQuit={handleCancel}
      />
    </>
  );
}
