export default function ProductionBundlesSummary({ summaryData }) {
  return (
    <>
      <div className="flexbox-container-col">
        <span>Top 1</span>
        <span>Top 2</span>
        <span>Top 3</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{":"}</span>
        <span>{":"}</span>
        <span>{":"}</span>
      </div>
      <div className="flexbox-container-col">
        <span>{summaryData.top_diameter}</span>
        <span>{summaryData.second_diameter}</span>
        <span>{summaryData.last_diameter}</span>
      </div>
    </>
  );
}
