import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetDeliveryData() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [deliveryTableData, setDeliveryTableData] = useState([]);
  const [deliveryColumn, setDeliveryColumn] = useState([]);
  const [deliveryChartData, setDeliveryChartData] = useState([]);
  const [deliveryDownload, setDeliveryDownload] = useState([]);

  const getDeliveryData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistic/getDeliveryData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDeliveryTableData(fetch_output.table_data);
            setDeliveryColumn(fetch_output.table_column);
            setDeliveryChartData(fetch_output.chart_data);
            setDeliveryDownload(fetch_output.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.console.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Netword Error. Failed to get delivery table",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content: "Failed to Respond (Delivery Table), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getDeliveryData,
    deliveryTableData,
    deliveryColumn,
    deliveryChartData,
    deliveryDownload,
  };
}

export default useGetDeliveryData;
