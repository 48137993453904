import { Button, Card, Flex } from "antd";
import { useEffect, useState } from "react";
import useGetUserList from "../../functions/Management/getUserList";
import UserManagementTable from "../../components/management/user_management/UserManagementTable";
import { PlusOutlined } from "@ant-design/icons";
import UserManagementForm from "../../components/management/user_management/UserManagementForm";
import DiameterManagementTable from "../../components/management/diameter_management/DiameterManagementTable";

const MANAGEMENT_TAB = [
  {
    label: "User Management",
    key: "user_management",
  },
  {
    label: "Diameter Management",
    key: "diameter_management",
  },
  { label: "Mechanical Management", key: "mechanical_management" },
  {
    label: "Chemical Management",
    key: "chemical_management",
  },
];

export default function ManagementPage() {
  const [activeTab, setActiveTab] = useState("user_management");
  const [userModal, setUserModal] = useState(false);
  const [diameterModal, setDiameterModal] = useState(false);

  const [userFormLoading, setUserFormLoading] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [editDiameter, setEditDiameter] = useState(null);

  const { getUserList, userList, userListLoading } = useGetUserList();

  const onTabChange = (value) => setActiveTab(value);

  const handlers = {
    user_management: {
      addOpen: () => setUserModal(true),
      editOpen: (value) => {
        setEditUser(value);
        setUserModal(true);
      },
      addSubmit: (value) => {},
      editSubmit: (value) => {},
      deleteSubmit: (value) => {},
    },
    diameter_management: {
      editOpen: (value) => {
        setEditDiameter(value);
        setDiameterModal(true);
      },
      editSubmit: (value) => {},
      deleteSubmit: (value) => {},
    },
  };

  const handleAddOpen = () => {
    const handler = handlers[activeTab];
    if (handler && handler.addOpen) handler.addOpen();
  };

  const handleEditOpen = (value) => {
    const handler = handlers[activeTab];
    if (handler && handler.editOpen) handler.editOpen(value);
  };

  const handleFormSubmit = (value, key) => {
    const handler = handlers[activeTab];
    if (key == null) {
      if (handler && handler.addSubmit) handler.addSubmit();
    } else {
      if (handler && handler.editSubmit) handler.editSubmit();
    }
  };

  const handleDeleteSubmit = (key) => {
    const handler = handlers[activeTab];
    if (handler && handler.deleteSubmit) handler.deleteSubmit(key);
  };

  useEffect(() => {
    getUserList();
  }, []);

  const managementContent = {
    user_management: (
      <UserManagementTable
        tableData={userList}
        handleEdit={handleEditOpen}
        handleDelete={handleDeleteSubmit}
        deleteLoading={false}
      />
    ),
    diameter_management: <DiameterManagementTable />,
    mechanical_management: "Mechanical Management",
    chemical_management: "Chemical Management",
  };

  return (
    <>
      <Card
        className="context-card"
        tabList={MANAGEMENT_TAB}
        activeTabKey={activeTab}
        onTabChange={onTabChange}
        tabBarExtraContent={
          <Flex gap="small">
            {activeTab == "user_management" && (
              <Button icon={<PlusOutlined />} onClick={handleAddOpen} />
            )}
          </Flex>
        }
        loading={userListLoading}
      >
        {managementContent[activeTab]}
      </Card>
      <UserManagementForm
        modalOpen={userModal}
        setModalOpen={setUserModal}
        editData={editUser}
        setEditData={setEditUser}
        onSubmit={handleFormSubmit}
        loading={userFormLoading}
      />
    </>
  );
}
