import { Flex } from "antd";
import DataTable from "../../common/DataTable";
import FunctionalButton from "../../common/FunctionalButton";
import moment from "moment";
import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function MechanicalTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const MECHANICAL_TABLE_COLUMN = [
    {
      title: "No. ",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: "Heat No",
      dataIndex: "heat_no",
      key: "heat_no",
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 80,
    },
    {
      title: "Rebar Production Date",
      dataIndex: "rebar_production_date",
      key: "rebar_production_date",
      width: 180,
    },
    {
      title: "Size (mm)",
      dataIndex: "size",
      key: "size",
      width: 120,
    },
    {
      title: "Length (mm)",
      dataIndex: "length",
      key: "length",
      width: 120,
    },
    {
      title: "Bend Test",
      dataIndex: "bend_test",
      key: "bend_test",
      width: 120,
      render: (_, record) => {
        return (
          <span style={{ color: record.bend_test == "FAIL" ? "red" : "green" }}>
            {record.bend_test}
          </span>
        );
      },
    },
    {
      title: "Rebend Test",
      dataIndex: "rebend_test",
      key: "rebend_test",
      width: 120,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.rebend_test == "FAIL" ? "red" : "green" }}
          >
            {record.rebend_test}
          </span>
        );
      },
    },
    {
      title: "Yield (N/mm2)",
      dataIndex: "yield",
      key: "yield",
      width: 140,
    },
    {
      title: "Tensile (N/mm2)",
      dataIndex: "tensile",
      key: "tensile",
      width: 140,
    },
    {
      title: "Elongation (Agt %)",
      dataIndex: "elongation",
      key: "elongation",
      width: 140,
    },
    {
      title: "Ratio (TS/YS)",
      dataIndex: "ratio",
      key: "ratio",
      width: 140,
    },
    {
      title: "Weight (kg/m)",
      dataIndex: "weight",
      key: "weight",
      width: 140,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_, record) => {
        const updatedRecord = {
          ...record,
          rebar_production_date: record.rebar_production_date
            ? moment(record.rebar_production_date, "YYYY-MM-DD")
            : null,
        };
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(updatedRecord)}
              type=""
              width="40px"
              icon={<EditOutlined />}
              disabled={loadRowKey == record.key && deleteLoading}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleDelete(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to delete this material properties?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
              trigger_loading={loadRowKey == record.key && deleteLoading}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={MECHANICAL_TABLE_COLUMN}
      table_data={tableData}
      pageSize={10}
    />
  );
}
