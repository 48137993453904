import { Button, Flex, Form, Row } from "antd";
import CustomModal from "../../common/CustomModal";
import useGetUserTypeOption from "../../../functions/Options/getUserTypeOption";
import useGetFactoryOption from "../../../functions/Options/getFactoryOption";
import CustomFormItems from "../../common/CustomFormItems";
import { useEffect } from "react";

export default function UserManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [userManagementForm] = Form.useForm();

  const { userTypeOption } = useGetUserTypeOption();
  const { factoryOption } = useGetFactoryOption();

  const onFinish = (value) => {
    if (editData) {
      onSubmit(value, editData["key"]);
    } else onSubmit(value, null);
  };

  const handleClearForm = () => userManagementForm.resetFields();

  useEffect(() => {
    if (modalOpen == false) {
      userManagementForm.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) userManagementForm.setFieldsValue(editData);
  }, [editData]);

  const USER_MANAGEMENT_FORM = [
    {
      label: "Username",
      name: "username",
      type: "input",
      required: true,
    },
    {
      label: "Password",
      name: "password",
      type: "input",
      required: true,
    },
    {
      label: "User Type",
      name: "user_type",
      type: "select",
      options: userTypeOption,
      required: true,
    },
    {
      label: "Factory",
      name: "factory",
      type: "select",
      options: factoryOption,
      required: true,
    },
  ];

  return (
    <CustomModal
      title={editData ? "Edit User" : "Add User"}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={500}
      content={
        <Form form={userManagementForm} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <CustomFormItems
              form_properties={USER_MANAGEMENT_FORM}
              form_label={true}
            />
          </Row>
          <Flex gap="middle" justify="flex-end">
            <Form.Item>
              <Button
                onClick={handleClearForm}
                type="link"
                children="Clear All"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                children={editData ? "Update" : "Create"}
                loading={loading}
              />
            </Form.Item>
          </Flex>
        </Form>
      }
    />
  );
}
