import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetAllMechanicalProperties() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [mechanicalProperties, setMechanicalProperties] = useState([]);
  const [mechanicalLoading, setMechanicalLoading] = useState(false);

  const getAllMechanicalProperties = useCallback(() => {
    setMechanicalLoading(true);
    fetchWrapper({
      endpoint_url: "material_properties/getAllMechanicalProperties",
      setLoading: setMechanicalLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMechanicalProperties(fetch_output.data);
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to get all material properties",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (All Material Properties), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);
  return {
    getAllMechanicalProperties,
    mechanicalProperties,
    mechanicalLoading,
  };
}

export default useGetAllMechanicalProperties;
