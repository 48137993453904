import { Button, Divider, Flex, Form, Row } from "antd";
import CustomModal from "../../common/CustomModal";
import { useEffect, useState } from "react";
import CustomFormItems from "../../common/CustomFormItems";
import useGetChemicalMinMax from "../../../functions/MinMax/getChemicalMinMax";
import useGetDiameterTypeOption from "../../../functions/Options/getDiameterTypeOption";

export default function ChemicalModalForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [chemicalForm] = Form.useForm();
  const [chemicalCompostion, setChemicalComposition] = useState([]);
  const { chemicalMinMax } = useGetChemicalMinMax();

  const { diameterOption } = useGetDiameterTypeOption();

  const onFinish = (value) => {
    if (editData) {
      onSubmit(value, editData["key"]);
    } else onSubmit(value, null);
  };

  const mapChemicalForm = (value) => {
    setChemicalComposition(
      value.map((data) => {
        return {
          label: `${data.name} (${data.short_name})`,
          name: data.lower_name,
          type: "input-number",
          required: true,
          defaultValue: data.min,
          min: data.min,
          max: data.max,
          quarter: true,
        };
      })
    );
  };

  const handleClearForm = () => chemicalForm.resetFields();

  useEffect(() => {
    if (modalOpen == false) {
      chemicalForm.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) chemicalForm.setFieldsValue(editData);
  }, [editData]);

  useEffect(() => {
    mapChemicalForm(chemicalMinMax);
  }, [chemicalMinMax]);

  const HEAT_NUMBER_FORM = [
    {
      label: "Heat Number",
      name: "heat_no",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      options: diameterOption,
      required: true,
      quarter: true,
    },
    {
      label: "Ceq (%)",
      name: "ceq",
      type: "input-number",
      required: true,
      quarter: true,
    },
    {
      label: "Billet Production Date",
      name: "billet_production_date",
      type: "datetime-select",
      required: true,
    },
  ];

  return (
    <CustomModal
      title={
        editData ? "Edit Chemical Composition" : "Add Chemical Composition"
      }
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <Form form={chemicalForm} onFinish={onFinish}>
          <Divider
            orientation="left"
            orientationMargin="0"
            children="Heat Number"
          />
          <Row gutter={[16, 16]}>
            <CustomFormItems form_properties={HEAT_NUMBER_FORM} />
          </Row>
          <Divider
            orientation="left"
            orientationMargin="0"
            children="Chemical Composition"
          />
          <Row gutter={[16, 16]}>
            <CustomFormItems form_properties={chemicalCompostion} />
          </Row>
          <Flex gap="middle" justify="flex-end">
            <Form.Item>
              <Button
                onClick={handleClearForm}
                type="link"
                children="Clear All"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                children={editData ? "Update" : "Create"}
                loading={loading}
              />
            </Form.Item>
          </Flex>
        </Form>
      }
    />
  );
}
