import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useGetInventoryData() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [inventoryTableData, setInventoryTableData] = useState([]);
  const [inventoryColumn, setInventoryColumn] = useState([]);
  const [inventoryChartData, setInventoryChartData] = useState([]);
  const [inventoryDownload, setInventoryDownload] = useState([]);

  const getInventoryData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistic/getInventoryData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setInventoryTableData(fetch_output.table_data);
            setInventoryColumn(fetch_output.table_column);
            setInventoryChartData(fetch_output.chart_data);
            setInventoryDownload(fetch_output.download_data);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Netword Error. Failed to get inventory table",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Inventory Table), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getInventoryData,
    inventoryTableData,
    inventoryColumn,
    inventoryChartData,
    inventoryDownload,
  };
}

export default useGetInventoryData;
