import { render } from "@antv/g2";
import { Flex } from "antd";
import { useState } from "react";
import FunctionalButton from "../../common/FunctionalButton";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import DataTable from "../../common/DataTable";

export default function DiameterManagementTable(
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading
) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const DIAMETER_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Diameter",
      dataIndex: "diameter",
      key: "diameter",
    },
    {
      title: "Diameter Type",
      dataIndex: "diameter_type",
      key: "diameter",
    },
    {
      title: "Product Grade",
      dataIndex: "product_grade",
      key: "product_grade",
    },
    {
      title: "Mill Cert Name",
      dataIndex: "mill_cert_name",
      key: "mill_cert_name",
    },
    {
      title: "Mass Bundle",
      dataIndex: "mass_bundle",
      key: "mass_bundle",
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (_, record) => {
        return (
          <FunctionalButton
            buttonClickFunction={() => {}}
            type=""
            width="40px"
            icon={<EyeOutlined />}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => {}}
              type=""
              width="40px"
              icon={<EditOutlined />}
            />
            <FunctionalButton
              buttonClickFunction={() => {}}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to delete this diameter?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={DIAMETER_TABLE_COLUMN}
      table_data={[]}
      pageSize={10}
    />
  );
}
