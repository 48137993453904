import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function CustomRangePicker({
  startDateSelection,
  setStartDateSelection,
  endDateSelection,
  setEndDateSelection,
  disabled,
  timePicker = "date",
  search = false,
  showTime = false,
  onFilterClick,
}) {
  return (
    <Flex gap="small">
      {search && (
        <Button
          icon={<SearchOutlined />}
          onClick={onFilterClick}
          disabled={disabled}
        />
      )}
      <DatePicker.RangePicker
        format="DD/MM/YYYY"
        value={[startDateSelection, endDateSelection]}
        allowClear={false}
        showTime={showTime}
        picker={timePicker}
        onChange={(dates, _) => {
          setStartDateSelection(
            showTime ? dates[0] : dates[0] ? dates[0].startOf("day") : null
          );
          setEndDateSelection(
            showTime ? dates[1] : dates[1] ? dates[1].endOf("day") : null
          );
        }}
        disabledDate={(current) => {
          return dayjs() && current > dayjs();
        }}
        disabled={disabled}
        inputReadOnly={true}
      />
    </Flex>
  );
}
