import { Button, Divider, Flex, Form, Row } from "antd";
import CustomModal from "../../common/CustomModal";
import { useEffect, useState } from "react";
import CustomFormItems from "../../common/CustomFormItems";
import useGetMechanicalMinMax from "../../../functions/MinMax/getMechanicalMinMax";
import useGetDiameterTypeOption from "../../../functions/Options/getDiameterTypeOption";

const TEST_OPTIONS = [
  { label: "PASS", value: "PASS" },
  {
    label: "FAIL",
    value: "FAIL",
  },
];

export default function MechanicalModalForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [mechanicalForm] = Form.useForm();
  const [mechanicalProperties, setMechanicalProperties] = useState([]);
  const { mechanicalMinMax } = useGetMechanicalMinMax();

  const { diameterOption } = useGetDiameterTypeOption();

  const onFinish = (value) => {
    if (editData) {
      onSubmit(value, editData["key"]);
    } else onSubmit(value, null);
  };

  const mapChemicalForm = (value) => {
    setMechanicalProperties(
      value.map((data) => {
        return {
          label: `${data.name} (${data.short_name})`,
          name: data.lower_name,
          type: "input-number",
          required: true,
          defaultValue: data.min,
          min: data.min,
          max: data.max,
          quarter: true,
        };
      })
    );
  };

  const handleClearForm = () => mechanicalForm.resetFields();

  useEffect(() => {
    if (modalOpen == false) {
      mechanicalForm.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) mechanicalForm.setFieldsValue(editData);
  }, [editData]);

  useEffect(() => {
    mapChemicalForm(mechanicalMinMax);
  }, [mechanicalMinMax]);

  const HEAT_NUMBER_INFORMATION_FORM = [
    {
      label: "Heat Number",
      name: "heat_no",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      options: diameterOption,
      required: true,
      quarter: true,
    },
    {
      label: "Size (mm)",
      name: "size",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Length (mm)",
      name: "length",
      type: "input",
      required: true,
      quarter: true,
    },
    {
      label: "Rebar Production Date",
      name: "rebar_production_date",
      type: "datetime-select",
      required: true,
    },
    {
      label: "Bend Test",
      name: "bend_test",
      type: "select",
      quarter: true,
      required: true,
      options: TEST_OPTIONS,
    },
    {
      label: "Rebend Test",
      name: "rebend_test",
      type: "select",
      quarter: true,
      required: true,
      options: TEST_OPTIONS,
    },
  ];

  return (
    <CustomModal
      title={
        editData ? "Edit Mechanical Properties" : "Add Mechanical Properties"
      }
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <Form form={mechanicalForm} onFinish={onFinish}>
          <Divider
            orientation="left"
            orientationMargin="0"
            children="Heat Number & Information"
          />
          <Row gutter={[16, 16]}>
            <CustomFormItems form_properties={HEAT_NUMBER_INFORMATION_FORM} />
          </Row>
          <Divider
            orientation="left"
            orientationMargin="0"
            children="Mechanical Properties"
          />
          <Row gutter={[16, 16]}>
            <CustomFormItems form_properties={mechanicalProperties} />
          </Row>

          <Flex gap="middle" justify="flex-end">
            <Form.Item>
              <Button
                onClick={handleClearForm}
                type="link"
                children="Clear All"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                children={editData ? "Update" : "Create"}
                loading={loading}
              />
            </Form.Item>
          </Flex>
        </Form>
      }
    />
  );
}
