import { message } from "antd";
import useConfig from "antd/es/config-provider/hooks/useConfig";
import { useContext, useEffect, useRef } from "react";
import { IndexContext } from "../../context/IndexContext";

export default function RebarVerificationEditImage({
  imageSrc,
  addCount,
  minusCount,
  shapeSize,
  setCoordinate,
  circles,
  crosses,
  setCircles,
  setCrosses,
}) {
  const canvasRef = useRef(null);
  const longClickDuration = 500;
  const timerId = useRef(null);
  const isLongClick = useRef(false);
  const isTouchEvent = useRef(false);

  const { messageApi } = useContext(IndexContext);

  const drawCanvasImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    canvas.width = width;
    canvas.height = height;

    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      // const aspectRatio = img.width / img.height;
      // canvas.width = canvas.parentElement.clientWidth;
      // canvas.height = canvas.width / aspectRatio;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      circles.forEach((circle) => {
        const pixelX = circle.x * canvas.width;
        const pixelY = circle.y * canvas.height;

        ctx.beginPath();
        ctx.arc(pixelX, pixelY, circle.shapeSize, 0, Math.PI * 2);
        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
      });

      crosses.forEach((cross) => {
        const { x, y, shapeSize } = cross;
        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;

        const pixelX = x * canvas.width;
        const pixelY = y * canvas.height;

        // Diagonal Line 1 (Top-left to Bottom-right)
        ctx.beginPath();
        ctx.moveTo(pixelX - shapeSize / 2, pixelY - shapeSize / 2);
        ctx.lineTo(pixelX + shapeSize / 2, pixelY + shapeSize / 2);
        ctx.stroke();

        // Diagonal Line 2 (Bottom-left to Top-right)
        ctx.beginPath();
        ctx.moveTo(pixelX - shapeSize / 2, pixelY + shapeSize / 2);
        ctx.lineTo(pixelX + shapeSize / 2, pixelY - shapeSize / 2);
        ctx.stroke();
      });
    };
  };

  const getDistance = (x1, y1, x2, y2) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleCircleClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    // Normalize the click coordinates
    const x = (event.clientX - rect.left) / canvas.width;
    const y = (event.clientY - rect.top) / canvas.height;

    // Find the circle where the click occurred within its radius (shapeSize/2)
    const existingCircle = circles.findIndex((circle) => {
      const distance = getDistance(circle.x, circle.y, x, y);
      // Calculate distance based on normalized x, y, and shape size
      return distance <= circle.shapeSize / canvas.width;
    });

    if (existingCircle !== -1) {
      const newCircles = [...circles];
      newCircles.splice(existingCircle, 1);
      setCircles(newCircles);
      minusCount();
    } else {
      const newCircle = { x, y, shapeSize, key: null };
      setCircles([...circles, newCircle]);
      addCount();
    }
  };

  const handleCrossClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    // Normalize the click coordinates
    const x = (event.clientX - rect.left) / canvas.width;
    const y = (event.clientY - rect.top) / canvas.height;

    // Find the cross where the click occurred within its radius (shapeSize/2)
    const existingCross = crosses.findIndex((cross) => {
      const distance = getDistance(cross.x, cross.y, x, y);
      // Calculate distance based on normalized x, y, and shape size
      return distance <= cross.shapeSize / canvas.width;
    });

    if (existingCross !== -1) {
      const newCrosses = [...crosses];
      newCrosses.splice(existingCross, 1);
      setCrosses(newCrosses);
      addCount();
    } else {
      const newCross = { x, y, shapeSize, key: null };
      setCrosses([...crosses, newCross]);
      minusCount();
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(timerId.current);
  };

  const handleMouseDown = (event) => {
    if (isTouchEvent.current) return;
    isLongClick.current = false;
    startLongClickTimer(event);
  };

  const handleMouseUp = (event) => {
    if (isTouchEvent.current) {
      isTouchEvent.current = false; // Reset the flag after touch event
      return; // Ignore mouse events if it's a touch event
    }
    clearTimeout(timerId.current);
    if (!isLongClick.current) {
      handleCircleClick(event);
    }
  };

  const handleTouchStart = (event) => {
    isTouchEvent.current = true;
    isLongClick.current = false;
    startLongClickTimer(event.touches[0]);
    event.preventDefault();
  };

  const handleTouchEnd = (event) => {
    clearTimeout(timerId.current);
    if (!isLongClick.current) {
      handleCircleClick(event.changedTouches[0]);
    }
    event.preventDefault();
  };

  const startLongClickTimer = (event) => {
    isLongClick.current = false;
    timerId.current = setTimeout(() => {
      isLongClick.current = true;
      handleCrossClick(event);
    }, longClickDuration);
  };

  useEffect(() => {
    drawCanvasImage();
    setCoordinate({
      circle: circles,
      cross: crosses,
    });
  }, [circles, crosses, imageSrc]);

  return (
    <canvas
      ref={canvasRef}
      className="canvas-image"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    />
  );
}
