import { Card, Row, Col } from "antd";
import InventoryPieChart from "./inventory/InventoryPieChart";
import InventoryDonutChart from "./inventory/InventoryDonutChart";
import InventoryBarChart from "./inventory/InventoryBarChart";

export default function InventoryCard({
  chartTextColor,
  pieChartData,
  donutChartData,
  barChartData,
  loading,
}) {
  return (
    <Card title="Inventory" style={{ height: "100%" }} loading={loading}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={7}>
          <InventoryPieChart
            data={pieChartData}
            chartTextColor={chartTextColor}
          />
        </Col>
        <Col xs={24} sm={7}>
          <InventoryDonutChart data={donutChartData} />
        </Col>
        <Col xs={24} sm={10}>
          <InventoryBarChart
            data={barChartData}
            chartTextColor={chartTextColor}
          />
        </Col>
      </Row>
    </Card>
  );
}
