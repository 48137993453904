import { Card, Col, Row } from "antd";
import IndicatorDonutChart from "./indicator/IndicatorDonutChart";
import IndicatorLineChart from "./indicator/IndicatorLineChart";

export default function IndicatorCard({
  chartTextColor,
  donutChartData,
  lineChartData,
  loading,
}) {
  return (
    <Card title="Indicator" style={{ height: "100%" }} loading={loading}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={12}>
          <IndicatorDonutChart data={donutChartData} />
        </Col>
        <Col xs={24} sm={12}>
          <IndicatorLineChart
            data={lineChartData}
            chartTextColor={chartTextColor}
          />
        </Col>
      </Row>
    </Card>
  );
}
