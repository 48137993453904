import { Line } from "@ant-design/plots";
import { Empty, Flex } from "antd";

export default function OperatorChart({ chartData, chartTextColor }) {
  const config = {
    data: chartData,
    xField: "time",
    yField: "value",
    seriesField: "type",
    colorField: "type",
    shapeField: "smooth",
    smooth: true,
    point: {
      shape: "circle",
      size: 4,
    },
    tooltip: {
      title: "day",
      items: [{ channel: "y" }],
    },
    axis: {
      x: {
        tickStroke: chartTextColor,
        labelFill: chartTextColor,
      },
      y: {
        tickStroke: chartTextColor,
        labelFill: chartTextColor,
      },
    },
    legend: {
      size: false,
      color: {
        itemLabelFill: chartTextColor,
      },
    },
  };
  return (
    <div style={{ height: "45vh" }}>
      {chartData.length ? (
        <Line {...config} />
      ) : (
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Empty />
        </Flex>
      )}
    </div>
  );
}
