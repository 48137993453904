export default function ShiftBundlesSummary({ summaryData }) {
  return (
    <>
      <div className="flexbox-container-col">
        <span>Top Shift</span>
        <span>Lowest Shift</span>
        <span>Average</span>
        <span>Current Shift</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{":"}</span>
        <span>{":"}</span>
        <span>{":"}</span>
        <span>{":"}</span>
      </div>
      <div className="flexbox-container-col">
        <span>{summaryData.top_shift}</span>
        <span>{summaryData.lowest_shift}</span>
        <span>{summaryData.average}</span>
        <span
          style={{
            color:
              summaryData.current_shift === "Below Avg" ? "orange" : "green",
          }}
        >
          {summaryData.current_shift}
        </span>
      </div>
    </>
  );
}
