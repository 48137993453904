import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useCallback } from "react";

function useCheckUserLogin() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();

  const checkUserLogin = useCallback(() => {
    fetchWrapper({
      endpoint_url: "user_management/checkUser",
      onSuccess: (response) => {
        if (response.status) {
          if (response.user_type !== 4) {
            navigate("/BundleList", { replace: true });
          } else {
            navigate("/AIRebarCount", { replace: true });
          }
        }
      },
    });
  }, [fetchWrapper]);

  return { checkUserLogin };
}

export default useCheckUserLogin;
