import { Line } from "@ant-design/plots";
import { Empty, Flex } from "antd";

export default function IndicatorLineChart({ data, chartTextColor }) {
  const config = {
    data,
    title: {
      title: "Current Output vs 7 Days Average",
      align: "center",
      titleFill: chartTextColor,
    },
    xField: "time",
    yField: "value",
    seriesField: "category",
    legend: {
      size: false,
      color: {
        itemLabelFill: chartTextColor,
      },
    },
    colorField: "category",
    point: {
      shape: "square",
      size: 4,
    },
    tooltip: {
      title: "time",
      items: [{ channel: "y" }],
    },
    axis: {
      x: {
        tickStroke: chartTextColor,
        labelFill: chartTextColor,
      },
      y: {
        tickStroke: chartTextColor,
        labelFill: chartTextColor,
      },
    },
  };

  return (
    <div style={{ height: "22vh" }}>
      {data.length ? (
        <Line {...config} />
      ) : (
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Empty />
        </Flex>
      )}
    </div>
  );
}
