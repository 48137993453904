import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useAuthCheck from "../hooks/useAuthCheck";

const ProtectedRoute = ({
  children,
  allowedUserTypes = [],
  disabledUserTypes = [],
}) => {
  const [authenticationState, getAuthenticationState, isLoading] =
    useAuthCheck();

  const [isUserAllowed, setIsUserAllowed] = useState(null);

  useEffect(() => {
    if (authenticationState.user_type !== undefined) {
      if (allowedUserTypes.length > 0) {
        setIsUserAllowed(
          allowedUserTypes.includes(authenticationState.user_type)
        );
      } else if (disabledUserTypes.length > 0) {
        setIsUserAllowed(
          !disabledUserTypes.includes(authenticationState.user_type)
        );
      }
    }
  }, [authenticationState]);

  return isLoading ? (
    <Flex
      style={{ height: "100%", width: "100%" }}
      align="center"
      justify="center"
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 120,
              color: "#000038",
            }}
            spin
          />
        }
      />
    </Flex>
  ) : (
    isUserAllowed != null &&
      (isUserAllowed ? children : <Navigate to="/Login" />)
  );
};

export default ProtectedRoute;
