import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import CustomModal from "../common/CustomModal";
import { useEffect } from "react";

const { Text } = Typography;

export default function MillCertificationForm({
  form,
  visible,
  modalTitle,
  handleFinish,
  handleCancel,
  visibleData,
  loading,
}) {
  // For Reset the Form Field
  const onResetForm = () => {
    form.resetFields();
  };

  // For Submit the Form
  const onFinish = (value) => {
    if (visibleData) {
      handleFinish(value, visibleData["key"]);
    } else {
      handleFinish(value);
    }
  };

  // If have Data set it to Field
  useEffect(() => {
    if (visibleData) {
      form.setFieldsValue(visibleData);
    }
  }, [visibleData]);

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={handleCancel}
      content={
        <>
          <Form form={form} onFinish={onFinish}>
            <Divider orientation="left" orientationMargin="0">
              D.O.
            </Divider>
            <Row gutter={24}>
              <Col xs={24} sm={12}>
                <Text>Customer</Text>
                <Form.Item name="customer">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Text>Specification</Text>
                <Form.Item name="specification">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <Text>Bar Marker</Text>
                <Form.Item name="bar_marker">
                  <Select />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Text>Deliver Order ID</Text>
                <Form.Item name="deliver_order_id">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Text>Number of Rebar Bundles</Text>
                <Form.Item name="number_of_rebar_bundles">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text>Address</Text>
                <Form.Item name="address_1">
                  <Input />
                </Form.Item>
                <Form.Item name="address_2">
                  <Input />
                </Form.Item>
                <Form.Item name="address_3">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Flex gap="middle" justify="flex-end">
              <Form.Item>
                <Button onClick={onResetForm}>Clear All</Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Create Job
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </>
      }
    />
  );
}
