import { Flex, Timeline } from "antd";
import CustomModal from "../common/CustomModal";
import { Children, useEffect, useState } from "react";

export default function TraceabilityModal({
  visible,
  timelineData,
  timelineSerial,
  onModalCancel,
}) {
  const [timelineShow, setTimelineShow] = useState([]);

  useEffect(() => {
    if (timelineData && timelineData.length) {
      setTimelineShow(
        timelineData.map((data) => {
          return {
            children: (
              <Flex vertical="vertical">
                <span>{data.stage}</span>
                <span>{data.by}</span>
                <span>{data.count}</span>
                <span>{data.datetime}</span>
              </Flex>
            ),
          };
        })
      );
    }
  }, [timelineData]);

  return (
    <CustomModal
      visible={visible}
      title={`TimeLine - ${timelineSerial}`}
      content={<Timeline mode="alternate" items={timelineShow} />}
      onCancel={onModalCancel}
    />
  );
}
