import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import DataTable from "../common/DataTable";

export default function MillCertificationTable({ tableData, handleEditData }) {
  const MILL_CERTIFICATION_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "DO",
      dataIndex: "do_number",
      key: "do_number",
      width: 140,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 160,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 300,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 240,
    },
    {
      title: "Description of Good",
      dataIndex: "description_of_good",
      key: "description_of_good",
      width: 250,
    },
    {
      title: "Number of Bundles",
      dataIndex: "number_of_bundles",
      key: "number_of_bundles",
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Flex gap="middle">
            <Button
              children="Edit"
              onClick={() => handleEditData(record.action)}
            />
            <Button children="Delete" danger={true} type="primary" />
            <Button icon={<DownloadOutlined />} />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        table_column={MILL_CERTIFICATION_TABLE_COLUMN}
        table_data={tableData}
      />
    </>
  );
}
