import { useCallback, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useNavigate } from "react-router-dom";

function useCheckUserLoginRecord() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const checkUserLoginRecord = useCallback(() => {
    fetchWrapper({
      endpoint_url: "user_management/checkUserLoginRecord",
      onSuccess: (response) => {
        if (!response.status) {
          navigate("/Login", { replace: true });
          messageApi.open({
            type: "warning",
            content: response.error,
          });
        } else {
          if (response.record) {
            if (response.user_type === 3) {
              navigate("/BundleList", { replace: true });
            } else if (response.user_type === 2) {
              navigate("/BundleList", { replace: true });
            } else {
              navigate("/AIRebarCount", { replace: true });
            }
            messageApi.open({
              type: "warning",
              content: response.message,
            });
          }
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to check user login record",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Check User Login Record), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { checkUserLoginRecord };
}

export default useCheckUserLoginRecord;
