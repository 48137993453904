import { Form } from "antd";
import CustomModal from "../common/CustomModal";
import CustomSearchForm from "../common/CustomSearchForm";

const BUNDLE_LIST_FILTER = [
  {
    label: "Product",
    name: "product",
    type: "input",
  },
  {
    label: "Serial No",
    name: "tag_id",
    type: "input",
  },
  {
    label: "Stage",
    name: "stage",
    type: "select",
    options: [
      { label: "After Bundling", value: "After Bundling" },
      {
        label: "Load",
        value: "Load",
      },
      {
        label: "Rework",
        value: "Rework",
      },
    ],
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: [
      { label: "Verified", value: "Verified" },
      {
        label: "AI Processed",
        value: "AI Processed",
      },
      {
        label: "Verifying",
        value: "Verifying",
      },
    ],
  },
  {
    label: "Rebar Qty (Last Update)",
    name: "rebar_qty",
    type: "input-number",
  },
  {
    label: "Last Update",
    name: "last_update",
    type: "range-select",
  },
  {
    label: "Last Update Operator",
    name: "last_update_operator",
    type: "input",
  },
  {
    label: "Remark",
    name: "remark",
    type: "select",
    options: [
      {
        label: "Rework",
        value: "rework",
      },
      {
        label: "Matched",
        value: "matched",
      },
    ],
  },
];

// Sort Field Option for the Advanced Search Form
const BUNDLE_LIST_SORT = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Serial Number",
    value: "tag_id",
  },
  {
    label: "Rebar Qty",
    value: "rebar_qty",
  },
  {
    label: "Last Update",
    value: "last_update",
  },
  {
    label: "Last Update Operator",
    value: "last_update_operator",
  },
];

export default function BundleListSearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [bundleListSearch] = Form.useForm();

  // To handle the Advanced Search Form Result
  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true;

        if (key === "last_update" && Array.isArray(filterValue)) {
          const [startDate, endDate] = filterValue;
          const itemDate = new Date(itemValue);
          return (
            itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
          );
        }

        return itemValue === filterValue;
      });
    });
  };

  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={bundleListSearch}
          onSubmit={handleSubmit}
          filterForm={BUNDLE_LIST_FILTER}
          sorterOption={BUNDLE_LIST_SORT}
        />
      }
    />
  );
}
