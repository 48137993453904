import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useAddTagID() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [tagIdLoading, setTagIdLoading] = useState(false);

  const addTagID = useCallback(
    (tagIdData, setTagId, setState, resetQrScanner) => {
      return new Promise((resolve, reject) => {
        setTagIdLoading(true);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/addTagID",
          params: {
            tag_id_data: tagIdData,
          },
          setLoading: setTagIdLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              setTagId({ key: fetch_output.key, value: fetch_output.tag_id });
              setState("rebarState");

              resetQrScanner();

              if (fetch_output.rework) {
                messageApi.open({
                  type: "success",
                  content: "This bundle will be rework stage",
                });
              } else {
                messageApi.open({
                  type: "success",
                  content: fetch_output.message,
                });
              }
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to add Tag ID",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content: "Failed to Respond (Add Tag ID), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { addTagID, tagIdLoading };
}

export default useAddTagID;
