import { useContext, useEffect, useRef, useState } from "react";
import { IndexContext } from "../../../context/IndexContext";
import ShiftChart from "./ShiftChart";
import ShiftTable from "./ShiftTable";

export default function ShiftPage({ chartData, tableData, tableColumn }) {
  const { isLightTheme } = useContext(IndexContext);
  const [chartTextColor, setChartTextColor] = useState(
    isLightTheme ? "#000000" : "#ffffff"
  );

  useEffect(() => {
    setChartTextColor(isLightTheme ? "#000000" : "#ffffff");
  }, [isLightTheme]);

  return (
    <>
      <ShiftChart chartData={chartData} chartTextColor={chartTextColor} />
      <ShiftTable tableData={tableData} tableColumn={tableColumn} />
    </>
  );
}
