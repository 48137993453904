import { Col, Divider, Flex, Progress, Row, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import { IndexContext } from "../../context/IndexContext";

const { Text, Title } = Typography;

// Rebar Count Progress for the Bundle Summary
const REBAR_COUNT_PROGRESS = {
  ai_count: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#D9D9D9" }} />
      AI Counted
    </Flex>
  ),
  verified: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#00DE43" }} />
      Verified
    </Flex>
  ),
};

// Breakdown Verified for the Bundle Summary
const BREAKDOWN_VERIFIED = {
  adjusted: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#D9D9D9" }} />
      Adjusted
    </Flex>
  ),
  non_adjust: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#1890FF" }} />
      Non-Adjust
    </Flex>
  ),
};

// Function to show Rebar Count Progress Summary
function RebarCountProgressSummary({ ai_count, verified }) {
  return (
    <Flex>
      <div className="flexbox-container-col" style={{ minWidth: "180px" }}>
        {REBAR_COUNT_PROGRESS.ai_count}
        {REBAR_COUNT_PROGRESS.verified}
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{`:`}</span>
        <span>{`:`}</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <span>{ai_count}</span>
        <span>{verified}</span>
      </div>
    </Flex>
  );
}

// Function to show Breakdown Verified Summary
function BreakdownVerifiedSummary({ adjusted, non_adjust }) {
  return (
    <Flex>
      <div className="flexbox-container-col" style={{ minWidth: "180px" }}>
        {BREAKDOWN_VERIFIED.adjusted}
        {BREAKDOWN_VERIFIED.non_adjust}
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{`:`}</span>
        <span>{`:`}</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <span>{adjusted}</span>
        <span>{non_adjust}</span>
      </div>
    </Flex>
  );
}

// Function to create a Bundle Summary Pie Chart
function BreakdownVerifiedDonut({ data }) {
  const { isLightTheme } = useContext(IndexContext);

  const configData = [
    {
      type: "Not-Adjusted",
      value: data.non_adjust,
    },
    {
      type: "Adjusted",
      value: data.adjusted,
    },
  ];

  const total = data.non_adjust + data.adjusted;
  const nonAdjustPercentage = ((data.non_adjust / total) * 100).toFixed(0);

  const config = {
    data: configData,
    angleField: "value",
    colorField: "type",
    style: {
      inset: 1,
      radius: 5,
    },
    tooltip: {
      title: "type",
    },
    radius: 0.8,
    innerRadius: 0.6,
    legend: false,
    scale: {
      color: {
        palette: ["#1890FF", "#D9D9D9"],
      },
    },
    annotations: [
      {
        type: "text",
        style: {
          text: `${nonAdjustPercentage == "NaN" ? 0 : nonAdjustPercentage}%`,
          x: "50",
          y: "50",
          textAlign: "center",
          fontStyle: "bold",
          fontSize: 16,
          fill: isLightTheme ? "#000000" : "#ffffff",
        },
        tooltip: false,
      },
    ],
  };

  return (
    <div style={{ height: "180px", width: "300px" }}>
      <Pie {...config} />
    </div>
  );
}

// Function for each Bundle Stage
function BundleStage({ aiProcess, verified, pending, stage }) {
  return (
    <>
      <Divider
        className="stage-divider"
        type="vertical"
        style={{ height: "180px" }}
      />
      <Flex vertical="vertical" align="center" gap="small">
        <span className="bundle-stage-type">{stage}</span>
        <Flex
          vertical="vertical"
          justify="center"
          style={{ alignItems: "center" }}
        >
          <span className="bundle-stage-title">{aiProcess}</span>
          <span className="bundle-stage-text">AI Processed</span>
        </Flex>
        <Flex
          vertical="vertical"
          justify="center"
          style={{ alignItems: "center" }}
        >
          <span className="bundle-stage-title">{verified}</span>
          <span className="bundle-stage-text">Verified</span>
        </Flex>
        <Flex
          vertical="vertical"
          justify="center"
          style={{ alignItems: "center" }}
        >
          <span className="bundle-stage-title">{pending}</span>
          <span className="bundle-stage-text">Pending Verification</span>
        </Flex>
      </Flex>
    </>
  );
}

export default function BundleListSummary({ data }) {
  // To store the Rebar Count Data
  const [rebarCount, setRebarCount] = useState({});
  // To store the Breakdown Verified Data
  const [breakdownVerified, setBreakdownVerified] = useState({});
  // To store the Stage Data
  const [stageData, setStageDate] = useState([]);

  useEffect(() => {
    if (data) {
      setRebarCount(data.rebar_count_progress);
      setBreakdownVerified(data.breakdown_verified);
      setStageDate(data.stage);
    }
  }, [data]);

  return (
    <Row
      gutter={[8, 8]}
      style={{
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Col xxl={4}>
        <Flex
          vertical="vertical"
          align="center"
          justify="center"
          style={{ height: "100%" }}
        >
          <Title>{data.bundles_complete}</Title>
          <Text>Bundles Delivered</Text>
        </Flex>
      </Col>
      <Col xxl={5}>
        <Flex
          vertical="vertical"
          align="center"
          justify="space-between"
          gap="large"
          style={{ height: "100%" }}
        >
          <Text style={{ fontSize: "18px", fontWeight: "bold" }}>
            Rebar Count Progress
          </Text>
          <Progress
            size={[300, 20]}
            percent={(
              (rebarCount.verified / rebarCount.ai_counted) *
              100
            ).toFixed(0)}
            strokeColor="#00DE43"
          />
          <RebarCountProgressSummary
            ai_count={rebarCount.ai_counted}
            verified={rebarCount.verified}
          />
        </Flex>
      </Col>

      <Col xxl={5}>
        <Flex
          vertical="vertical"
          align="center"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Text style={{ fontSize: "18px", fontWeight: "bold" }}>
            Breakdown of Verified Bundles
          </Text>
          <BreakdownVerifiedDonut data={breakdownVerified} />
          <BreakdownVerifiedSummary
            adjusted={breakdownVerified.adjusted}
            non_adjust={breakdownVerified.non_adjust}
          />
        </Flex>
      </Col>
      <Col xxl={9}>
        <Flex wrap="wrap" align="center" justify="center" gap="large">
          {stageData.map((value) => (
            <BundleStage
              aiProcess={value.ai_process}
              verified={value.verified}
              pending={value.pending}
              stage={value.stage}
            />
          ))}
        </Flex>
      </Col>
    </Row>
  );
}
