import { SettingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
  Tag,
  TimePicker,
  Typography,
} from "antd";
import { useState } from "react";
import CustomFormItems from "./CustomFormItems";

const { Text } = Typography;

const SORT_ORDER_OPTION = [
  {
    label: "Ascending",
    value: "Ascending",
  },
  {
    label: "Descending",
    value: "Descending",
  },
];

export default function CustomSearchForm({
  form,
  onSubmit,
  filterForm,
  sorterOption,
}) {
  // To store the Sort Tags in Sorters
  const [sortTags, setSortTags] = useState([]);
  // To store the Sort Field Input Value in Sorters
  const [sortField, setSortField] = useState(null);
  // To store the Sort Order Input Value in Sorters
  const [sortOrder, setSortOrder] = useState(null);

  // Function to add Sorter Tag
  const addSorter = () => {
    const newSortTags = `${sortField}: ${sortOrder}`;
    const tagExist = sortTags.includes(newSortTags);
    const fieldExist = sortTags.some((tag) => tag.sortField == sortField);

    if (tagExist) {
      message.error("Already have same sorters");
    } else if (fieldExist) {
      message.error("Already have same field sorters");
    } else {
      setSortTags([
        ...sortTags,
        { sortField: sortField, sortOrder: sortOrder },
      ]);
      setSortField(null);
      setSortOrder(null);
    }
  };

  // Function to show Sort Field Label at the Sorter Tag
  const getFieldLabel = (value) => {
    const field = sorterOption.find((option) => option.value === value);
    return field ? field.label : value;
  };

  // Function to remove the Sorter Tag
  const handleTagClose = (removeTag) => {
    const filterSortTags = sortTags.filter((tag) => tag !== removeTag);
    setSortTags(filterSortTags);
  };

  // Function to reset the Search Form
  const handleClearForm = () => {
    form.resetFields();
    setSortField(null);
    setSortOrder(null);
    setSortTags([]);
  };

  // Function to submit the Search Form
  const handleFinish = (value) => {
    onSubmit({ ...value, sortTags });
    handleClearForm();
  };

  return (
    <Form form={form} onFinish={handleFinish}>
      <Divider orientation="left" orientationMargin="0" children="Filters" />
      <Row gutter={24}>
        <CustomFormItems form_properties={filterForm} />
      </Row>

      <Divider orientation="left" orientationMargin="0" children="Sorters" />

      <Row gutter={[24, 16]}>
        <Col xs={24} sm={16}>
          <Flex vertical="vertical" gap="middle">
            <Text children="Sort By" />
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12}>
                <Select
                  placeholder="Select Field"
                  style={{ width: "100%" }}
                  options={sorterOption}
                  value={sortField}
                  onChange={(value) => setSortField(value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Select
                  placeholder="Select Sort Order"
                  style={{ width: "100%" }}
                  options={SORT_ORDER_OPTION}
                  value={sortOrder}
                  onChange={(value) => setSortOrder(value)}
                />
              </Col>
            </Row>
            <Button
              icon={<SettingOutlined />}
              style={{ width: "100%" }}
              type="dashed"
              disabled={sortField == null || sortOrder == null}
              onClick={() => addSorter()}
              children="Add Sorter"
            />
          </Flex>
        </Col>

        <Col xs={24} sm={8}>
          {sortTags.map((tag, index) => {
            return (
              <Tag
                key={index}
                closable
                onClose={() => handleTagClose(tag)}
                style={{ marginBottom: "12px" }}
              >
                {`${getFieldLabel(tag.sortField)}: ${tag.sortOrder}`}
              </Tag>
            );
          })}
        </Col>
      </Row>

      <Flex gap="middle" justify="flex-end">
        <Form.Item>
          <Button
            onClick={() => handleClearForm()}
            type="link"
            children="Clear All"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" children="Search" />
        </Form.Item>
      </Flex>
    </Form>
  );
}
