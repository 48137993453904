import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";

function useGetRebarImageCoordinate() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [circles, setCircles] = useState([]);
  const [crosses, setCrosses] = useState([]);
  const [shapeSize, setShapeSize] = useState(0);
  const [coordinateLoading, setCoordinateLoading] = useState(false);

  const getRebarImageCoordinate = useCallback(
    (rebar_key) => {
      setCoordinateLoading(true);
      fetchWrapper({
        endpoint_url: "rebar_verification/getRebarImageCoordinate",
        params: {
          rebar_id: rebar_key,
        },
        setLoading: setCoordinateLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setCircles(fetch_ouput.circle_coordinate);
            setCrosses(fetch_ouput.cross_coordinate);
            setShapeSize(fetch_ouput.shape_size);
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_ouput.error,
            });
          }
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content: "Network Error. Failed to get rebar image coordinate",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Rebar Image Coordinate), Please Contact Admin",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return {
    getRebarImageCoordinate,
    circles,
    crosses,
    shapeSize,
    setCircles,
    setCrosses,
    coordinateLoading,
  };
}

export default useGetRebarImageCoordinate;
