import { Form } from "antd";
import CustomModal from "../../common/CustomModal";
import CustomSearchForm from "../../common/CustomSearchForm";

const CHEMICAL_FILTER = [
  {
    label: "Heat No",
    name: "heat_no",
    type: "input",
    quarter: true,
  },
  {
    label: "Product",
    name: "product",
    type: "input",
    quarter: true,
  },
  {
    label: "Billet Production Date",
    name: "billet_production_date",
    type: "range-select",
  },
  {
    label: "Ceq (%)",
    name: "ceq",
    type: "input",
    quarter: true,
  },
  {
    label: "Carbon",
    name: "carbon",
    type: "input",
    quarter: true,
  },
  {
    label: "Silicon",
    name: "silicon",
    type: "input",
    quarter: true,
  },
  {
    label: "Manganese",
    name: "manganese",
    type: "input",
    quarter: true,
  },
  {
    label: "Phosphorus",
    name: "phosphorus",
    type: "input",
    quarter: true,
  },
  {
    label: "Sulphur",
    name: "sulphur",
    type: "input",
    quarter: true,
  },
  {
    label: "Copper",
    name: "copper",
    type: "input",
    quarter: true,
  },
  {
    label: "Nickel",
    name: "nickel",
    type: "input",
    quarter: true,
  },
  {
    label: "Chromium",
    name: "chromium",
    type: "input",
    quarter: true,
  },
  {
    label: "Vanadium",
    name: "vanadium",
    type: "input",
    quarter: true,
  },
  {
    label: "Aluminium",
    name: "aluminium",
    type: "input",
    quarter: true,
  },
  {
    label: "Molybdenum",
    name: "molybdenum",
    type: "input",
    quarter: true,
  },
  {
    label: "Tin",
    name: "tin",
    type: "input",
    quarter: true,
  },
  {
    label: "Boron",
    name: "boron",
    type: "input",
    quarter: true,
  },
  {
    label: "Nitrogen",
    name: "nitrogen",
    type: "input",
    quarter: true,
  },
  {
    label: "Cerium",
    name: "cerium",
    type: "input",
    quarter: true,
  },
];

const CHEMICAL_SORT = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Heat No",
    value: "heat_no",
  },
  {
    label: "Billet Production Date",
    value: "billet_production_date",
  },
];

export default function ChemicalSearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [chemicalSearch] = Form.useForm();

  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true;

        if (key === "billet_production_date" && Array.isArray(filterValue)) {
          const [startDate, endDate] = filterValue;
          const itemDate = new Date(itemValue);
          return (
            itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
          );
        }

        return itemValue === filterValue;
      });
    });
  };

  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={chemicalSearch}
          onSubmit={handleSubmit}
          filterForm={CHEMICAL_FILTER}
          sorterOption={CHEMICAL_SORT}
        />
      }
    />
  );
}
