import { Form } from "antd";
import CustomModal from "../../common/CustomModal";
import CustomSearchForm from "../../common/CustomSearchForm";

const TEST_OPTIONS = [
  { label: "PASS", value: "PASS" },
  {
    label: "FAIL",
    value: "FAIL",
  },
];

const MECHANICAL_FILTER = [
  {
    label: "Heat No",
    name: "heat_no",
    type: "input",
    quarter: true,
  },
  {
    label: "Product",
    name: "product",
    type: "input",
    quarter: true,
  },
  {
    label: "Size",
    name: "size",
    type: "input",
    quarter: true,
  },
  {
    label: "Length",
    name: "length",
    type: "input-number",
    quarter: true,
  },
  {
    label: "Rebar Production Date",
    name: "rebar_prodution_date",
    type: "range-select",
  },
  {
    label: "Bend Test",
    name: "bend_test",
    type: "select",
    options: TEST_OPTIONS,
    quarter: true,
  },
  {
    label: "Rebend Test",
    name: "rebend_test",
    type: "select",
    options: TEST_OPTIONS,
    quarter: true,
  },
  {
    label: "Yield",
    name: "yield",
    type: "input",
    quarter: true,
  },
  {
    label: "Tensile",
    name: "tensile",
    type: "input",
    quarter: true,
  },
  {
    label: "Elongation",
    name: "elongation",
    type: "input",
    quarter: true,
  },
  {
    label: "Ratio",
    name: "ratio",
    type: "input",
    quarter: true,
  },
  {
    label: "Weight",
    name: "weight",
    type: "input",
    quarter: true,
  },
];

const MECHANICAL_SORT = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Heat No",
    value: "heat_no",
  },
  {
    label: "Rebar Production Date",
    value: "rebar_production_date",
  },
];

export default function MechanicalSearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [mechanicalSearch] = Form.useForm();

  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true;

        if (key === "rebar_production_date" && Array.isArray(filterValue)) {
          const [startDate, endDate] = filterValue;
          const itemDate = new Date(itemValue);
          return (
            itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
          );
        }

        return itemValue === filterValue;
      });
    });
  };

  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={mechanicalSearch}
          onSubmit={handleSubmit}
          filterForm={MECHANICAL_FILTER}
          sorterOption={MECHANICAL_SORT}
        />
      }
    />
  );
}
