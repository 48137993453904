import { Button, Card, Flex, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import InventoryPage from "../../components/statistic/inventory/InventoryPage";
import OperatorPage from "../../components/statistic/operator/OperatorPage";
import ShiftPage from "../../components/statistic/shift/ShiftPage";
import StatisticWIPPage from "../../components/statistic/statistic-wip/StatisticWIPPage";
import DeliveryPage from "../../components/statistic/delivery/DeliveryPage";
import useGetFactoryOption from "../../functions/Options/getFactoryOption";
import useGetDiameterTypeOption from "../../functions/Options/getDiameterTypeOption";
import useGetOperatorOption from "../../functions/Options/getOperatorOption";
import dayjs from "dayjs";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import useGetInventoryData from "../../functions/Statistic/Inventory/getInventoryData";
import useGetDeliveryData from "../../functions/Statistic/Delivery/getDeliveryData";
import useGetStatisticWipData from "../../functions/Statistic/Statistic-WIP/getStatisticWipData";
import useGetOperatorData from "../../functions/Statistic/Operator/getOperatorData";
import useGetShiftData from "../../functions/Statistic/Shift/getShiftData";
import useDownloadInventory from "../../functions/Statistic/Inventory/downloadInventory";
import useDownloadShift from "../../functions/Statistic/Shift/downloadShift";
import useDownloadOperator from "../../functions/Statistic/Operator/downloadOperator";
import useDownloadStatisticWip from "../../functions/Statistic/Statistic-WIP/downloadStatisticWip";
import useDownloadDelivery from "../../functions/Statistic/Delivery/downloadDelivery";

const TIME_OPTION = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Year",
    value: "year",
  },
];

const statisticTab = [
  {
    label: "Inventory",
    key: "inventory",
  },
  {
    label: "Shift",
    key: "shift",
  },
  {
    label: "Operator",
    key: "operator",
  },
  {
    label: "Statistic-WIP",
    key: "statistic_wip",
  },
  {
    label: "Delivery",
    key: "delivery",
  },
];

export default function StatisticPage() {
  const [activeTab, setActiveTab] = useState("inventory");
  const [isLoading, setIsLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const onTabChange = (value) => setActiveTab(value);
  const firstRender = useRef(true);

  // For handle datepicker startdate
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  // For handle datepicker enddate
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  // For handle select time option
  const [timeSelect, setTimeSelect] = useState(TIME_OPTION[0].value);
  // For get and handle factory option
  const { factoryOption, factorySelect, setFactorySelect } =
    useGetFactoryOption();
  // For get and handle diameter option
  const { diameterOption, diameterSelect, setDiameterSelect } =
    useGetDiameterTypeOption();
  // For get and handle operator option
  const { operatorOption, operatorSelect, setOperatorSelect } =
    useGetOperatorOption();

  // For handle Factory Select Value
  const handleFactorySelect = (value) => setFactorySelect(value);
  // For handle Diameter Select Value
  const handleDiameterSelect = (value) => setDiameterSelect(value);
  // For handle Time Select Value
  const handleTimeSelect = (value) => setTimeSelect(value);
  // For handle Operator Select Value
  const handleOperatorSelect = (value) => setOperatorSelect(value);

  // For get and handle inventory table data, chart data and column
  const {
    getInventoryData,
    inventoryTableData,
    inventoryColumn,
    inventoryChartData,
    inventoryDownload,
  } = useGetInventoryData();
  const { downloadInventory } = useDownloadInventory();
  // For get and handle shift table data, chart data and column
  const {
    getShiftData,
    shiftTableData,
    shiftColumn,
    shiftChartData,
    shiftDownload,
  } = useGetShiftData();
  const { downloadShift } = useDownloadShift();
  // For get and handle operator table data, chart data and column
  const {
    getOperatorData,
    operatorTableData,
    operatorColumn,
    operatorChartData,
    operatorDownload,
  } = useGetOperatorData();
  const { downloadOperator } = useDownloadOperator();
  // For get and handle statistic-wip table data, chart data and column
  const {
    getStatisticWipData,
    statisticWipTableData,
    statisticWipColumn,
    statisticWipChartData,
    statisticWipDownload,
  } = useGetStatisticWipData();
  const { downloadStatisticWip } = useDownloadStatisticWip();
  // For get and handle delivery table data, chart data and column
  const {
    getDeliveryData,
    deliveryTableData,
    deliveryColumn,
    deliveryChartData,
    deliveryDownload,
  } = useGetDeliveryData();
  const { downloadDelivery } = useDownloadDelivery();

  const handleInventoryData = () => {
    getInventoryData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  const handleShiftData = () => {
    getShiftData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  const handleOperatorData = () => {
    getOperatorData(
      factorySelect,
      diameterSelect,
      operatorSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  const handleStatisticWipData = () => {
    getStatisticWipData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  const handleDeliveryData = () => {
    getDeliveryData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  const handlers = {
    inventory: {
      apply: handleInventoryData,
      download: () => downloadInventory(inventoryDownload, setDownloadLoading),
    },
    shift: {
      apply: handleShiftData,
      download: () => downloadShift(shiftDownload, setDownloadLoading),
    },
    operator: {
      apply: handleOperatorData,
      download: () => downloadOperator(operatorDownload, setDownloadLoading),
    },
    statistic_wip: {
      apply: handleStatisticWipData,
      download: () =>
        downloadStatisticWip(statisticWipDownload, setDownloadLoading),
    },
    delivery: {
      apply: handleDeliveryData,
      download: () => downloadDelivery(deliveryDownload, setDownloadLoading),
    },
  };

  const handleApply = () => {
    const handler = handlers[activeTab];
    if (handler && handler.apply) {
      handler.apply();
    }
  };

  const handleDownload = () => {
    const handler = handlers[activeTab];
    if (handler && handler.download) {
      handler.download();
    }
  };

  const statisticContent = {
    inventory: (
      <InventoryPage
        chartData={inventoryChartData}
        tableData={inventoryTableData}
        tableColumn={inventoryColumn}
      />
    ),
    shift: (
      <ShiftPage
        chartData={shiftChartData}
        tableData={shiftTableData}
        tableColumn={shiftColumn}
      />
    ),
    operator: (
      <OperatorPage
        chartData={operatorChartData}
        tableData={operatorTableData}
        tableColumn={operatorColumn}
      />
    ),
    statistic_wip: (
      <StatisticWIPPage
        chartData={statisticWipChartData}
        tableData={statisticWipTableData}
        tableColumn={statisticWipColumn}
      />
    ),
    delivery: (
      <DeliveryPage
        chartData={deliveryChartData}
        tableData={deliveryTableData}
        tableColumn={deliveryColumn}
      />
    ),
  };

  // For only call api once the select changes
  useEffect(() => {
    if (
      factorySelect != null &&
      diameterSelect != null &&
      operatorOption != null
    ) {
      if (firstRender.current) {
        firstRender.current = false;
        handleInventoryData();
        handleShiftData();
        handleOperatorData();
        handleDeliveryData();
        handleStatisticWipData();
      }
    }
  }, [factorySelect, diameterSelect, operatorSelect]);

  return (
    <Card
      className="context-card"
      tabList={statisticTab}
      activeTabKey={activeTab}
      onTabChange={onTabChange}
      loading={isLoading}
    >
      <Flex gap="small" wrap align="center" justify="space-between">
        <Select
          mode="multiple"
          style={{ width: "200px" }}
          options={factoryOption}
          value={factorySelect}
          onChange={handleFactorySelect}
          placeholder="Factory Select"
          disabled={isLoading}
        />
        <Flex gap="small" wrap align="center">
          {activeTab === "operator" && (
            <Select
              mode="multiple"
              style={{ width: "320px" }}
              options={operatorOption}
              value={operatorSelect}
              onChange={handleOperatorSelect}
              placeholder="Operator Select"
              maxCount={5}
              disabled={isLoading}
            />
          )}

          <Select
            mode="multiple"
            style={{ width: "320px" }}
            options={diameterOption}
            value={diameterSelect}
            onChange={handleDiameterSelect}
            placeholder="Diameter Type Select"
            maxCount={5}
            disabled={isLoading}
          />
          <CustomRangePicker
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            disabled={isLoading}
            timePicker={timeSelect}
          />
          <Select
            style={{ width: "100px" }}
            options={TIME_OPTION}
            value={timeSelect}
            onChange={handleTimeSelect}
            placeholder="Month"
            disabled={isLoading}
          />
          <Button
            type="primary"
            disabled={isLoading}
            children="Apply"
            onClick={handleApply}
          />
          <Button
            icon={downloadLoading ? <LoadingOutlined /> : <DownloadOutlined />}
            disabled={isLoading || downloadLoading}
            onClick={handleDownload}
          />
        </Flex>
      </Flex>
      {statisticContent[activeTab]}
    </Card>
  );
}
