import {
  Button,
  Form,
  Modal,
  Upload,
  Typography,
  Flex,
  Image,
  Tooltip,
} from "antd";
import CustomFormItems from "../common/CustomFormItems";
import { useState } from "react";
import { DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";

const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
    padding: 0,
  },
  wrapperCol: {
    span: 16,
  },
};

// Fot the form properties
const REBAR_FORM_PROPERTIES = [
  { type: "input-number", name: "rebar_count", label: "Rebar Count" },
];

export default function ManualInsertModalForm({
  modalOpen,
  uploadLoading,
  handleUpload,
  handleSubmit,
  formLoading,
  imageSrc,
  removeImage,
}) {
  // For handle the Image Upload Visible
  const [previewVisible, setPreviewVisible] = useState(false);
  const [errorForm] = Form.useForm();

  // For the Form Submit
  const submitForm = (value) => {
    handleSubmit(value);
    errorForm.resetFields();
  };

  return (
    <Modal
      open={modalOpen}
      footer={null}
      closable={true}
      title={"Rebar Insert Form"}
      centered
    >
      <Form
        form={errorForm}
        {...layout}
        labelAlign="left"
        style={{ marginTop: "20px" }}
        onFinish={submitForm}
      >
        <Form.Item label={<Text strong>{"Rebar Image"}</Text>}>
          <Flex gap="middle">
            <Upload
              accept="image/x-png, image/jpeg"
              capture="environment"
              maxCount={1}
              listType="picture"
              customRequest={handleUpload}
              itemRender={(each_item) => {
                return "";
              }}
              rootClassName="upload-button-max-width"
            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                loading={uploadLoading}
                style={{ width: "100%" }}
                disabled={imageSrc != null}
              >
                Upload Image
              </Button>
            </Upload>
            {imageSrc != null && (
              <>
                <Tooltip title="Click this to preview image">
                  <Button
                    type="ghost"
                    icon={<EyeOutlined />}
                    onClick={() => setPreviewVisible(true)}
                  />
                </Tooltip>
                <Image
                  src={imageSrc}
                  style={{ display: "none" }}
                  preview={{
                    visible: previewVisible,
                    onVisibleChange: (value) => {
                      setPreviewVisible(value);
                    },
                  }}
                />
                <Button
                  type="ghost"
                  icon={<DeleteOutlined />}
                  onClick={removeImage}
                />
              </>
            )}
          </Flex>
        </Form.Item>
        <CustomFormItems form_properties={REBAR_FORM_PROPERTIES} />
        <Flex gap="middle" justify="flex-end">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={formLoading}>
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
}
