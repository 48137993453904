export default function OperatorBundlesSummary({ summaryData }) {
  return (
    <>
      <div className="flexbox-container-col">
        <span>Top Operator</span>
        <span>Lowest Operator</span>
        <span>Average</span>
        <span>Gap (Average-lowest)</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{":"}</span>
        <span>{":"}</span>
        <span>{":"}</span>
        <span>{":"}</span>
      </div>
      <div className="flexbox-container-col">
        <span>{summaryData.top_operator}</span>
        <span>{summaryData.lowest_operator}</span>
        <span>{summaryData.average}</span>
        <span>{summaryData.gap}</span>
      </div>
    </>
  );
}
