import { Pie } from "@ant-design/plots";
import { Empty, Flex } from "antd";

export default function InventoryPieChart({ data, chartTextColor }) {
  const config = {
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      text: (d) => `${d.type}: ${d.percentage}%`,
      position: "outside",
      fill: chartTextColor,
    },
    tooltip: {
      title: "type",
    },
    legend: {
      color: {
        title: false,
        position: "bottom",
        rowPadding: 5,
        itemLabelFill: chartTextColor,
      },
    },
  };

  return (
    <div style={{ height: "22vh" }}>
      {data.length ? (
        <Pie {...config} />
      ) : (
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Empty />
        </Flex>
      )}
    </div>
  );
}
