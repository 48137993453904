import {
  AuditOutlined,
  CalculatorOutlined,
  CameraOutlined,
  DownloadOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  MergeOutlined,
  PieChartOutlined,
  QrcodeOutlined,
  TableOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";

const header_height = 58;

export default function useNavMenu(authenticationState) {
  const user_type = authenticationState.user_type;

  const NavMenu = user_type != null && [
    (user_type == 0 || user_type == 1) && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <ToolOutlined style={{ marginRight: 12 }} />
          Testing
        </Flex>
      ),
      key: "Test",
      children: [
        {
          label: (
            <Flex align="center" justify="start">
              <CameraOutlined style={{ marginRight: 8 }} />
              Camera Test
            </Flex>
          ),
          key: "CameraTest",
        },
        {
          label: (
            <Flex align="center" justify="start">
              <QrcodeOutlined style={{ marginRight: 8 }} />
              QrScanner Test
            </Flex>
          ),
          key: "QrScannerTest",
        },
        {
          label: (
            <Flex align="center" justify="start">
              <DownloadOutlined style={{ marginRight: 8 }} />
              Download Test
            </Flex>
          ),
          key: "DownloadTest",
        },
        {
          label: (
            <Flex align="center" justify="start">
              <TableOutlined style={{ marginRight: 8 }} />
              Table Data Test
            </Flex>
          ),
          key: "TableDataTest",
        },
      ],
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <CameraOutlined style={{ marginRight: 12 }} />
          AI Rebar Count
        </Flex>
      ),
      key: "AIRebarCount",
    },
    user_type !== 4 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <FileTextOutlined style={{ marginRight: 12 }} />
          Bundle List
        </Flex>
      ),
      key: "BundleList",
    },
    user_type == 0 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <FundProjectionScreenOutlined style={{ marginRight: 12 }} />
          Dashboard
        </Flex>
      ),
      key: "Dashboard",
    },
    user_type == 0 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <CalculatorOutlined style={{ marginRight: 12 }} />
          Statistic
        </Flex>
      ),
      key: "Statistic",
    },
    user_type == 0 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <MergeOutlined style={{ marginRight: 12 }} />
          Traceability
        </Flex>
      ),
      key: "Traceability",
    },
    user_type == 0 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <PieChartOutlined style={{ marginRight: 12 }} />
          Management
        </Flex>
      ),
      key: "Management",
    },
    user_type == 0 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <AuditOutlined style={{ marginRight: 12 }} />
          Mill Certification
        </Flex>
      ),
      key: "MillCertification",
    },
    user_type == 0 && {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <FileProtectOutlined style={{ marginRight: 12 }} />
          Material Properties
        </Flex>
      ),
      key: "MaterialProperties",
    },
  ];

  return [NavMenu, header_height];
}
