import { Form } from "antd";
import CustomModal from "../common/CustomModal";
import CustomSearchForm from "../common/CustomSearchForm";

const TRACEABILITY_FILTER = [
  {
    label: "Product",
    name: "product",
    type: "input",
  },
  {
    label: "Serial No",
    name: "serial_no",
    type: "input",
  },
  {
    label: "Heat No",
    name: "heat_no",
    type: "input",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: [
      { label: "Verified", value: "Verified" },
      {
        label: "AI Processed",
        value: "AI Processed",
      },
      {
        label: "Verifying",
        value: "Verifying",
      },
    ],
  },
  {
    label: "AI Count",
    name: "ai_count",
    type: "input-number",
  },
  {
    label: "Mill Cert ID",
    name: "mill_cert_id",
    type: "input",
  },
  {
    label: "Operator ID",
    name: "opertaor_id",
    type: "input",
  },
  {
    label: "Photo Date Time",
    name: "photo_date_time",
    type: "range-select",
  },
  {
    label: "Supervisor ID",
    name: "supervisor_id",
    type: "input",
  },
  {
    label: "Validate Date Time",
    name: "validate_date_time",
    type: "range-select",
  },
];

// Sort Field Option for the Advanced Search Form
const TRACEABILITY_SORT = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Serial No",
    value: "serial_no",
  },
  {
    label: "AI Count",
    value: "ai_count",
  },
  {
    label: "Operator ID",
    value: "operator_id",
  },
  {
    label: "Photo Date Time",
    value: "photo_date_time",
  },
  {
    label: "Supervisor ID",
    value: "supervisor_id",
  },
  {
    label: "Validate Date Time",
    value: "validate_date_time",
  },
  {
    label: "Mill Cert ID",
    value: "mill_cert_id",
  },
];

export default function TraceabilitySearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [traceabilitySearch] = Form.useForm();

  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true; // Skip undefined or null filters

        if (
          (key === "photo_date_time" || key === "validate_date_time") &&
          Array.isArray(filterValue)
        ) {
          const [startDate, endDate] = filterValue;
          const itemDate = new Date(itemValue);
          return (
            itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
          );
        }

        return itemValue === filterValue;
      });
    });
  };

  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={traceabilitySearch}
          onSubmit={handleSubmit}
          filterForm={TRACEABILITY_FILTER}
          sorterOption={TRACEABILITY_SORT}
        />
      }
    />
  );
}
