import { Button, Card, Flex, Form, Typography } from "antd";
import MillCertificationTable from "../../components/mill_certification/MillCertificationTable";
import { PlusOutlined } from "@ant-design/icons";
import MillCertificationForm from "../../components/mill_certification/MillCertificationForm";
import { useState } from "react";

const { Text } = Typography;

const MILL_CERTIFICATION_DATA = [
  {
    do_number: "BRRM 28808",
    customer: "MALAYSIA STEEL WORKS (KL) BHD",
    address:
      "29-JALAN TADANG, SECTION 51, 46050 PETALING JAYA, SELANGOR DARUL EHSAN",
    specification: "GREAD 250",
    description_of_good: "MILD STEEL ROUND BAR",
    number_of_bundles: 75,
    status: "Generated",
    action: {},
  },
  {
    do_number: "BRRM 28809",
    customer: "MALAYSIA STEEL WORKS (KL) BHD",
    address:
      "29-JALAN TADANG, SECTION 51, 46050 PETALING JAYA, SELANGOR DARUL EHSAN",
    specification: "GREAD 250",
    description_of_good: "MILD STEEL ROUND BAR",
    number_of_bundles: 55,
    status: "Pending",
    action: {},
  },
  {
    do_number: "BRRM 28810",
    customer: "MALAYSIA STEEL WORKS (KL) BHD",
    address:
      "29-JALAN TADANG, SECTION 51, 46050 PETALING JAYA, SELANGOR DARUL EHSAN",
    specification: "MS146 2014 (GREAD B500B)",
    description_of_good: "HIGH TENSIEL DEFORMED BARS",
    number_of_bundles: 60,
    status: "Pending",
    action: {},
  },
  {
    do_number: "BRRM 28811",
    customer: "MALAYSIA STEEL WORKS (KL) BHD",
    address:
      "29-JALAN TADANG, SECTION 51, 46050 PETALING JAYA, SELANGOR DARUL EHSAN",
    specification: "MS146 2014 (GREAD B500B)",
    description_of_good: "HIGH TENSIEL DEFORMED BARS",
    number_of_bundles: 60,
    status: "Pending",
    action: {},
  },
  {
    do_number: "BRRM 28812",
    customer: "MALAYSIA STEEL WORKS (KL) BHD",
    address:
      "29-JALAN TADANG, SECTION 51, 46050 PETALING JAYA, SELANGOR DARUL EHSAN",
    specification: "SS560:2016 (GREAD B500B)",
    description_of_good: "HIGH TENSIEL DEFORMED BARS",
    number_of_bundles: 60,
    status: "In Progress",
    action: {},
  },
];

export default function MillCertificationPage() {
  const [millCertificationInsertForm] = Form.useForm();
  // For handle Insert Modal Form
  const [insertVisible, setInsertVisible] = useState(false);

  const [millCertificationEditForm] = Form.useForm();
  // For handle Edit Modal Form
  const [editVisible, setEditVisible] = useState(false);
  // For handle Edit Data
  const [visibleData, setVisibleData] = useState({});

  // For Submit Insert Data
  const handleInsertConfirm = (value) => {
    console.log("Insert Value: ", value);
    millCertificationInsertForm.resetFields();
  };
  const handleInsertCancel = () => setInsertVisible(false);

  // For Submit Edit Data
  const handleEditConfirm = (value) => {
    console.log("Edit Value: ", value);
    millCertificationEditForm.resetFields();
  };
  const handleEditCancel = () => setEditVisible(false);

  // For get the Edit Data
  const handleEditData = (value) => {
    setEditVisible(true);
    setVisibleData(value);
  };

  return (
    <Card
      title={
        <Flex justify="space-between" align="center" wrap="wrap" gap="small">
          <Text>Mill Certification Table</Text>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setInsertVisible(true)}
          />
        </Flex>
      }
      style={{ width: "100%", height: "100%" }}
    >
      <MillCertificationTable
        tableData={MILL_CERTIFICATION_DATA}
        handleEditData={handleEditData}
      />
      <MillCertificationForm
        form={millCertificationInsertForm}
        visible={insertVisible}
        modalTitle="Mill Cert Information"
        handleFinish={handleInsertConfirm}
        handleCancel={handleInsertCancel}
        loading={false}
      />
      <MillCertificationForm
        form={millCertificationEditForm}
        visible={editVisible}
        modalTitle="Edit Mill Cert Information"
        handleFinish={handleEditConfirm}
        handleCancel={handleEditCancel}
        visibleData={visibleData}
        loading={false}
      />
    </Card>
  );
}
